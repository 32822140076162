import Typography from "@mui/material/Typography";
import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: "#F9F9F9",
    color: "#171832",
    fontSize: "12px",
    
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  id: string,
  name: string,
  group: string,
  stockQty: number,
  stockLeft: number
) {
  return { id, name, group, stockQty, stockLeft };
}

const rows = [
  createData(
    "D06ID232435454",
    "Augmentin 625 Duo Tablet",
    "Generic Medicine",
    350,
    200
  ),
  createData(
    "D06ID232435454",
    "Augmentin 625 Duo Tablet",
    "Generic Medicine",
    350,
    25
  ),
  // createData("Eclair", 262, 16.0, 24, 6.0),
  // createData("Cupcake", 305, 3.7, 67, 4.3),
  // createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export const Results = () => {
  const navigate = useNavigate();

  const drug = "Argumentin";
  return (
    <Box
      sx={{
        pl: "35px",
        pr: "103px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "30px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "24px",
            // width: "100%",
            color: "#45079A",
            lineHeight: "30px",
            alignSelf: "flex-start",
            display: "flex",
          }}
        >
          Search Medicine
        </Typography>
        <Button
          onClick={() => navigate("/pharmacy/search")}
          variant="text"
          startIcon={<KeyboardBackspaceTwoToneIcon />}
          sx={{
            textTransform: "none",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#45079A",
            display: "flex",
          }}
        >
          Back
        </Button>
      </Box>
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontSize: "18px",
            lineHeight: "23px",
            color: "#232323",
          }}
        >
          Found 2 of 20 <br />
          {drug} Result
        </Typography>
      </Box>
      <TableContainer
        elevation={0}
        sx={{ mt: 4, borderRadius: "8px", border: "1px solid #ddd" }}
        component={Paper}
      >
        <Table
          sx={{ minWidth: 700, borderRadius: "8px" }}
          aria-label="customized table"
        >
          <TableHead sx={{}}>
            <TableRow>
              <StyledTableCell>Medicine ID</StyledTableCell>
              <StyledTableCell sx={{ minWidth: "10px" }} align="center">
                .
              </StyledTableCell>
              <StyledTableCell align="left">Medicine Name</StyledTableCell>
              <StyledTableCell sx={{ minWidth: "10px" }} align="center">
                .
              </StyledTableCell>
              <StyledTableCell align="left">Group Name</StyledTableCell>
              <StyledTableCell sx={{ minWidth: "10px" }} align="center">
                .
              </StyledTableCell>
              <StyledTableCell align="left">Stock In QTY</StyledTableCell>
              <StyledTableCell sx={{ minWidth: "10px" }} align="center">
                .
              </StyledTableCell>
              <StyledTableCell align="left">Stock Left</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ borderBottom: "1px solid #FFFFFF" }}
                >
                  {row.id}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ borderBottom: "1px solid #ddd", minWidth: "10px" }}
                ></StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ borderBottom: "1px solid #FFFFFF" }}
                >
                  {row.name}{" "}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ borderBottom: "1px solid #ddd", minWidth: "10px" }}
                ></StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ borderBottom: "1px solid #FFFFFF" }}
                >
                  {row.group}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ borderBottom: "1px solid #ddd", minWidth: "10px" }}
                ></StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ borderBottom: "1px solid #FFFFFF" }}
                >
                  {row.stockQty}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ borderBottom: "1px solid #ddd", minWidth: "10px" }}
                ></StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ borderBottom: "1px solid #FFFFFF" }}
                >
                  <Chip
                    label={row.stockLeft}
                    sx={{
                      background: row.stockLeft > 49 ? "#F0FFF8" : "#FFF0F0",
                      color: row.stockLeft > 49 ? "#18AB56" : "#EB5757",
                      borderRadius: "8px",
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
