import {useState} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
// import ImageIcon from "@mui/icons-material/Image";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import sun from '../Assets/Images/sun.png'
import flag from '../Assets/Images/flag.png'




  


const theme = createTheme({
  palette: {
    primary: {

      main: "#E8EAF6",
    },
    secondary: {
      main: "#454081",
    },
  },
  typography: {
    fontFamily: ["Mulish", "Bold"].join(","),
  },
});

export default function ButtonAppBar() {

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          elevation={1}
          color="primary"
        >
          <Toolbar
          
          sx={{pr: "50px"}}>
            <Box sx={{
              display:'flex',
              width: '100%',
              direction: "row",
              // pr: "70px"


            }}>
              <List sx={{ maxWidth: "200px", ml: "70%" }}>
                <ListItem>
                  <ListItemAvatar
                    sx={{ justifyContent: "center", display: "flex", minWidth: "40px" }}
                  >
                    <Avatar
                      alt="Pharm"
                      src={flag}
                      sx={{ width: 24, height: 24 }}
                    />
                  </ListItemAvatar>
                  <ListItemText  primary="English (US)" onClick={handleClick}/>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </List>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "60px",
                  // ml: "20px",
                  pt: 2,
                  minWidth: "180px",
                  alignContent: "space-around"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    
                  }}
                >
                  <Avatar
                    alt="sun"
                    src={sun}
                    sx={{ width: 16, height: 16, mr: 1 }}
                  />
                  <Typography
                    // justifyContent="space-between"
                    variant="caption"
                  >
                    Good Morning
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // width: '30%',
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    14 January 2022
                  </Typography>
                  <Typography variant="caption">22:45:04</Typography>
                </Box>
              </Box>
            </Box>
            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
