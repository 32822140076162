import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
// import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
import ButtonAppBar from "./Appbar";
// import BeachAccessIcon from "@mui/icons-material/BeachAccess";
// import ListSubheader from "@mui/material/ListSubheader";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
// import DraftsIcon from "@mui/icons-material/Drafts";
import Badge from "@mui/material/Badge";
// import ArrowLeft from "@mui/icons-material/ArrowLeft";
import { useNavigate, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import {
  SearchIcon,
  OverviewIcon,
  InventoryIcon,
  InventoryLightIcon,
  LogoutIcon,
  SupportIcon,
  FirstDropDownIcon,
  SecondDropDownIcon,
  CashierIcon,
  ReportIcon,
  ReportLightIcon,
  SettingsIcon,
  NightModeIcon,
  IOSSwitch,
} from "../Components/icons";
import Divider from "@mui/material/Divider";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import FormControlLabel from "@mui/material/FormControlLabel";
import avatar from "../Assets/Images/Avatar.png";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#45079A",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#454081",
    },
  },
  typography: {
    fontFamily: ["Mulish", "Bold"].join(","),
  },
});
const drawerWidth = 232;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(13.25)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(14.25)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function PharmacyDrawer({ children }: any) {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [inventoryDropOpen, setInventoryDropOpen] = React.useState(false);
  const [salesDropOpen, setSalesDropOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
    if (inventoryDropOpen === true) {
      setInventoryDropOpen(false);
    }
    if (salesDropOpen === true) {
      setSalesDropOpen(false);
    }
  };

  const handleInventory = () => {
    setOpen(true);
    setInventoryDropOpen(!inventoryDropOpen);
  };

  const handleSales = () => {
    setOpen(true);
    setSalesDropOpen(!salesDropOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    {
      id: 1,
      text: "SEARCH",
      icon: (
        <SearchIcon
          sx={{
            color:
            location.pathname === "/pharmacy/search" && open
              ? "#FFFFFF"
              : location.pathname === "/pharmacy/search"
              ? "#45079A"
              : "#45079A",
          }}
        />
      ),
      path: "/pharmacy/search",
    },
    {
      id: 2,
      text: "OVERVIEW",
      icon: (
        <OverviewIcon
          sx={{
            color:
              location.pathname === "/pharmacy/overview" && !open ? "#E3D4F8" : "#FFFFFF",
            stroke:
              location.pathname === "/pharmacy/overview" ? "#FFFFFF" : "#45079A",
          }}
        />
      ),
      path: "/pharmacy/overview",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ButtonAppBar />
        <Drawer variant="permanent" open={open}>
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              <ListItem sx={{ height: "72px", ml: -2 }}>
                <ListItemButton
                  onClick={toggleDrawer}
                  sx={{
                    "& svg": {
                      // color: "rgba(255,255,255,0.8)",
                      transition: "0.2s",
                      transform: "translateX(0) rotate(0)",
                    },
                    "&:hover, &:focus": {
                      bgcolor: "unset",
                      // "& svg:first-of-type": {
                      //   transform: "translateX(-4px) rotate(-20deg)",
                      // },
                      "& svg:last-of-type": {
                        right: 0,
                        opacity: 1,
                      },
                    },
                    "&:after": {
                      content: '""',
                      position: "absolute",
                      height: "80%",
                      display: "block",
                      left: 0,
                      width: "1px",
                      bgcolor: "divider",
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Badge
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      color="primary"
                      badgeContent=" "
                      overlap="circular"
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={avatar}
                        sx={{ width: 48, height: 48 }}
                      />
                    </Badge>
                  </ListItemAvatar>
                  {open ? (
                    <ArrowLeft
                      sx={{
                        position: "absolute",
                        color: "#45079A",
                        right: 4,
                        opacity: 0,
                      }}
                    />
                  ) : (
                    <ArrowRight
                      sx={{
                        position: "absolute",
                        color: "#45079A",
                        right: 4,
                        opacity: 0,
                      }}
                    />
                  )}

                  {open ? (
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              color: "#45079A",
                              fontSize: "14px",
                              lineHeight: "24px",
                            }}
                            component="span"
                            variant="body2"
                          >
                            JOHN DOE
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#424242",
                            }}
                            component="span"
                            variant="body2"
                          >
                            D IN MEDICINE
                          </Typography>
                        </React.Fragment>
                      }

                      // sx={{
                      //   color: "#45079A"
                      // }}
                    />
                  ) : null}

                  <ListItemIcon>
                    {/* {open ? ( */}
                    {/* <InboxIcon
                  sx={{ position: "absolute", right: 4, opacity: 0 }}
                /> */}
                    {/* ) : null} */}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              {menuItems.map((item) => (
                <ListItem
                  onClick={() => navigate(item.path)}
                  key={item.id}
                  disablePadding
                  sx={{
                    display: "block",
                    // background:
                    //   location.pathname === item.path ? "#f4f4f4" : null,
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      color:
                        location.pathname === item.path ? "#FFFFFF" : "#45079A",
                      width: open ? "200px" : "48px",
                      height: open ? "48px" : "48px",
                      ml: 2,
                      mr: open ? 0 : 5,
                      mb: 0.5,
                      background:
                        location.pathname === item.path && open
                          ? "#45079A"
                          : location.pathname === item.path
                          ? "#E3D4F8"
                          : null,
                      borderRadius: "8px",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Paper>
                </ListItem>
              ))}
              <Paper
                onClick={() => navigate("/pharmacy/inventory")}
                elevation={0}
                sx={{
                  width: open || inventoryDropOpen ? "200px" : "48px",
                  height: open || inventoryDropOpen ? "48px" : "48px",
                  ml: 2,
                  mr: open ? 0 : 5,
                  mt: 0.5,
                  mb: open && inventoryDropOpen ? 0 : 0.5,

                  background:
                    (location.pathname === "/pharmacy/inventory/list" && open )||
                    (location.pathname === "/pharmacy/inventory/groups" && open) ||
                    (location.pathname === "/pharmacy/inventory" && open)
                      ? "#45079A"
                      :  location.pathname === "/pharmacy/inventory/list" ||
                    location.pathname === "/pharmacy/inventory/groups" ||
                    location.pathname === "/pharmacy/inventory" ? "#E3D4F8"
                      : null,

                  // background:
                  //   location.pathname === item.path && open
                  //     ? "#45079A"
                  //     : location.pathname === item.path
                  //     ? "#E3D4F8"
                  //     : null,
                  color:
                    location.pathname === "/pharmacy/inventory/list" ||
                    location.pathname === "/pharmacy/inventory/groups" ||
                    location.pathname === "/pharmacy/inventory"
                      ? "#FFFFFF"
                      : "#45079A",

                  // background:
                  //   location.pathname === "/pharmacy/inventory/list" ||
                  //   "/pharmacy/inventory/groups"
                  //     ? "#FFFFFF"
                  //     : "#45079A",

                  // color:
                  //   location.pathname === "/pharmacy/inventory/list" ||
                  //   "/pharmacy/inventory/groups"
                  //     ? "#45079A"
                  //     : "#FFFFFF",

                  // background: location.pathname === item.path ? "#45079A" : null,
                  // borderRadius: "8px",
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                  borderBottomRightRadius:
                    open && inventoryDropOpen ? "8px" : "8px",
                  borderBottomLeftRadius:
                    open && inventoryDropOpen ? "8px" : "8px",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={handleInventory}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {
                      (location.pathname === "/pharmacy/inventory/list" && !open) ||
                    (location.pathname === "pharmacy/inventory/groups" && !open) ||
                    (location.pathname === "/pharmacy/inventory" && !open) ? 
                      <InventoryIcon
                        sx={{
                          color: "#E3D4F8",
                        }}
                      />
                    :
                    location.pathname === "/pharmacy/inventory/list" ||
                    location.pathname === "pharmacy/inventory/groups" ||
                    location.pathname === "/pharmacy/inventory" ? (
                      <InventoryLightIcon sx={{ color: "#45079A" }} />
                    ) : (
                      <InventoryIcon
                        sx={{
                          color: "#FFFFFF",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  {open || inventoryDropOpen ? (
                    <ListItemText primary="INVENTORY" />
                  ) : null}
                  {open && inventoryDropOpen === false ? <ExpandMore /> : null}
                  {open && inventoryDropOpen ? <ExpandLess /> : null}
                </ListItemButton>
              </Paper>
              <Collapse in={inventoryDropOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Paper
                    elevation={0}
                    square
                    sx={{
                      width: open || inventoryDropOpen ? "200px" : "48px",
                      height: open || inventoryDropOpen ? "48px" : "48px",
                      ml: 2,
                      mr: open ? 0 : 5,
                      background:
                        location.pathname === "/pharmacy/inventory/list"
                          ? "#E3D4F8"
                          : "#F7F6FD",
                      color: "#45079A",
                      // borderRadius: "8px", #F7F6FD; #E3D4F8;
                    }}
                  >
                    <ListItemButton
                      onClick={() => navigate("/pharmacy/inventory/list")}
                      // sx={{
                      //   pl: 4,
                      //   background:
                      //     location.pathname === "pharmacy/inventory/list"
                      //       ? "#f4f4f4"
                      //       : null,
                      // }}
                    >
                      <ListItemIcon sx={{ minWidth: "34px" }}>
                        {" "}
                        <FirstDropDownIcon
                          sx={{
                            color: "#FFFFFF",
                            // location.pathname === "/pharmacy/inventory/list"
                            //   ? "#FFFFFF"
                            //   : "#45079A",
                          }}
                        />{" "}
                      </ListItemIcon>
                      <ListItemText primary="LIST OF MEDICINE" />
                    </ListItemButton>
                  </Paper>
                  <Paper
                    elevation={0}
                    square
                    sx={{
                      width: open || inventoryDropOpen ? "200px" : "48px",
                      height: open || inventoryDropOpen ? "48px" : "48px",
                      ml: 2,
                      mr: open ? 0 : 5,
                      mb: 0.5,
                      background:
                        location.pathname === "/pharmacy/inventory/groups"
                          ? "#E3D4F8"
                          : "#F7F6FD",
                      color: "#45079A",
                      borderBottomRightRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                  >
                    <ListItemButton
                      onClick={() => navigate("/pharmacy/inventory/groups")}
                      // sx={{
                      //   // pl: 4,
                      //   background:
                      //     location.pathname === "/pharmacy/inventory/groups"
                      //       ? "#f4f4f4"
                      //       : null,
                      // }}
                    >
                      <ListItemIcon sx={{ minWidth: "34px" }}>
                        {" "}
                        <SecondDropDownIcon
                          sx={{
                            color: "#FFFFFF",
                          }}
                        />{" "}
                      </ListItemIcon>
                      <ListItemText primary="MEDICINE GROUP" />
                    </ListItemButton>
                  </Paper>
                </List>
              </Collapse>

              <ListItem
                onClick={() => navigate("/pharmacy/cashier")}
                key="4"
                disablePadding
                sx={{
                  display: "block",
                  // background:
                  //   location.pathname === "/pharmacy/cashier" ? "#f4f4f4" : null,
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: open ? "200px" : "48px",
                    height: open ? "48px" : "48px",
                    ml: 2,
                    mr: open ? 0 : 5,
                    my: 0.5,
                    background:
                      location.pathname === "/pharmacy/cashier"
                        ? "#45079A"
                        : null,
                    color:
                      location.pathname === "/pharmacy/cashier"
                        ? "#FFFFFF"
                        : "#45079A",
                    borderRadius: "8px",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <CashierIcon
                        sx={{
                          color:
                            location.pathname === "/pharmacy/cashier"
                              ? "#FFFFFF"
                              : "#45079A",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="CASHIER"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <Paper
                onClick={() => navigate("/pharmacy/report")}
                elevation={0}
                sx={{
                  width: open || inventoryDropOpen ? "200px" : "48px",
                  height: open || inventoryDropOpen ? "48px" : "48px",
                  ml: 2,
                  mr: open ? 0 : 5,
                  mt: 0.5,
                  mb: open && salesDropOpen ? 0 : 0.5,
                  // background: location.pathname === item.path ? "#45079A" : null,
                  // borderRadius: "8px",

                  background:
                    location.pathname === "/pharmacy/report/sales" ||
                    location.pathname === "/pharmacy/report/payment-history" ||
                    location.pathname === "/pharmacy/report"
                      ? "#45079A"
                      : null,
                  color:
                    location.pathname === "/pharmacy/report/sales" ||
                    location.pathname === "/pharmacy/report/payment-history" ||
                    location.pathname === "/pharmacy/report"
                      ? "#FFFFFF"
                      : "#45079A",
                  // background:
                  //   location.pathname === "/pharmacy/report/sales" ||
                  //   "/pharmacy/report/payment-history"
                  //     ? "#FFFFFF"
                  //     : "#45079A",
                  // color:
                  //   location.pathname === "/pharmacy/report/sales" ||
                  //   "pharmacy/report/payment-history"
                  //     ? "#45079A"
                  //     : "#FFFFFF",
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                  borderBottomRightRadius:
                    open && salesDropOpen ? "8px" : "8px",
                  borderBottomLeftRadius: open && salesDropOpen ? "8px" : "8px",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={handleSales}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                      // minWidth: "34px",
                    }}
                  >
                    {location.pathname === "/pharmacy/report/sales" ||
                    location.pathname === "/pharmacy/report/payment-history" ||
                    location.pathname === "/pharmacy/report" ? (
                      <ReportLightIcon
                        sx={{
                          color: "#45079A",
                        }}
                      />
                    ) : (
                      <ReportIcon sx={{ color: "#FFFFFF" }} />
                    )}
                  </ListItemIcon>
                  {open || salesDropOpen ? (
                    <ListItemText primary="REPORTS" />
                  ) : null}
                  {open && salesDropOpen === false ? <ExpandMore /> : null}
                  {open && salesDropOpen ? <ExpandLess /> : null}
                </ListItemButton>
              </Paper>
              <Collapse in={salesDropOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Paper
                    elevation={0}
                    square
                    sx={{
                      width: open || inventoryDropOpen ? "200px" : "48px",
                      height: open || inventoryDropOpen ? "48px" : "48px",
                      ml: 2,
                      mr: open ? 0 : 5,
                      background:
                        location.pathname === "/pharmacy/report/sales"
                          ? "#E3D4F8"
                          : "#F7F6FD",
                      color: "#45079A",
                      // borderRadius: "8px", #F7F6FD; #E3D4F8;
                    }}
                  >
                    <ListItemButton
                      onClick={() => navigate("/pharmacy/report/sales")}
                      // sx={{
                      //   pl: 4,
                      //   background:
                      //     location.pathname === "pharmacy/report/sales"
                      //       ? "#f4f4f4"
                      //       : null,
                      // }}
                    >
                      <ListItemIcon sx={{ minWidth: "34px" }}>
                        {" "}
                        <FirstDropDownIcon
                          sx={{
                            color: "#FFFFFF",
                            // location.pathname === "/pharmacy/report/sales"
                            //   ? "#FFFFFF"
                            //   : "#45079A",
                          }}
                        />{" "}
                      </ListItemIcon>
                      <ListItemText primary="SALES REPORT" />
                    </ListItemButton>
                  </Paper>
                  <Paper
                    elevation={0}
                    square
                    sx={{
                      width: open || inventoryDropOpen ? "200px" : "48px",
                      height: open || inventoryDropOpen ? "48px" : "48px",
                      ml: 2,
                      mr: open ? 0 : 5,
                      mb: 0.5,
                      background:
                        location.pathname === "/pharmacy/report/payment-history"
                          ? "#E3D4F8"
                          : "#F7F6FD",
                      color: "#45079A",
                      borderBottomRightRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                  >
                    <ListItemButton
                      onClick={() =>
                        navigate("/pharmacy/report/payment-history")
                      }
                      // sx={{
                      //   pl: 4,
                      //   background:
                      //     location.pathname === "/pharmacy/report/payment-history"
                      //       ? "#f4f4f4"
                      //       : null,
                      // }}
                    >
                      <ListItemIcon sx={{ minWidth: "34px" }}>
                        {" "}
                        <SecondDropDownIcon
                          sx={{
                            color: "#FFFFFF",
                          }}
                        />{" "}
                      </ListItemIcon>
                      <ListItemText primary="PAYMENT HISTORY" />
                    </ListItemButton>
                  </Paper>
                </List>
              </Collapse>
              <ListItem
                onClick={() => navigate("/pharmacy/settings")}
                key="6"
                disablePadding
                sx={{
                  display: "block",
                  // background:
                  //   location.pathname === "pharmacy/settings" ? "#f4f4f4" : null,
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: open ? "200px" : "48px",
                    color:
                      location.pathname === "/pharmacy/settings"
                        ? "#FFFFFF"
                        : "#45079A",
                    height: open ? "48px" : "48px",
                    ml: 2,
                    mr: open ? 0 : 5,
                    my: 0.5,
                    pl: -0.5,
                    background:
                      location.pathname === "/pharmacy/settings"
                        ? "#45079A"
                        : null,
                    borderRadius: "8px",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "24px",
                        pl: -0.5,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <SettingsIcon
                        sx={{
                          stroke:
                            location.pathname === "/pharmacy/settings"
                              ? "#FFFFFF"
                              : "#45079A",
                          color: "#FFFFFF",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="SETTINGS"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Paper>
              </ListItem>

              <ListItem
                onClick={() => navigate("/pharmacy/support")}
                key="7"
                disablePadding
                sx={{
                  display: "block",
                  mt: 20,
                  mb: 1.5,
                  // background:
                  //   location.pathname === item.path ? "#f4f4f4" : null,
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    color:
                      location.pathname === "/pharmacy/support"
                        ? "#FFFFFF"
                        : "#45079A",
                    width: open ? "200px" : "48px",
                    height: open ? "48px" : "48px",
                    ml: 2,
                    mr: open ? 0 : 5,
                    mb: 0.5,
                    background:
                      location.pathname === "/pharmacy/support"
                        ? "#45079A"
                        : null,
                    borderRadius: "8px",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <SupportIcon
                        sx={{
                          color: "#FFFFFF",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="SUPPORT"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem
                // onClick={() => navigate("/pharmacy/support")}
                key="7"
                disablePadding
                sx={{
                  display: "block",
                  my: 1.5,
                  // background:
                  //   location.pathname === item.path ? "#f4f4f4" : null,
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    color:
                      location.pathname === "/pharmacy/logout"
                        ? "#FFFFFF"
                        : "#45079A",
                    width: open ? "200px" : "48px",
                    height: open ? "48px" : "48px",
                    ml: 2,
                    mr: open ? 0 : 5,
                    mb: 0.5,
                    background:
                      location.pathname === "/pharmacy/logout"
                        ? "#45079A"
                        : null,
                    borderRadius: "8px",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <LogoutIcon
                        sx={{
                          color: "#FFFFFF",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="LOG OUT"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <Divider />
              {/* <ListItem
              onClick={() => navigate("/pharmacy/night-mode")}
              key="7"
              disablePadding
              sx={{
                display: "block",
                my: 1.5,
                // background:
                //   location.pathname === item.path ? "#f4f4f4" : null,
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  color:
                    location.pathname === "/pharmacy/night-mode"
                      ? "#FFFFFF"
                      : "#45079A",
                  width: open ? "200px" : "48px",
                  height: open ? "48px" : "48px",
                  ml: 2,
                  mr: open ? 0 : 5,
                  mb: 0.5,
                  background:
                    location.pathname === "/pharmacy/night-mode"
                      ? "#45079A"
                      : null,
                  borderRadius: "8px",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <NightModeIcon
                      sx={{
                        color:
                          location.pathname === "/pharmacy/night-mode"
                            ? "#FFFFFF"
                            : "#45079A",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="NIGHT MODE"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  <IOSSwitch sx={{ m: 1 }} />
                </ListItemButton>
              </Paper>
            </ListItem> */}
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{
                      // ml: open ? 0 : -9,
                      display: open ? "inline-flex" : "none",
                    }}
                  />
                }
                label={
                  <Paper
                    elevation={0}
                    sx={{
                      color:
                        location.pathname === "/pharmacy/night-mode"
                          ? "#FFFFFF"
                          : "#45079A",
                      width: open ? "150px" : "48px",
                      height: open ? "48px" : "48px",
                      ml: open ? 0 : 8,
                      mr: open ? 0 : 2,
                      mb: 0.5,
                      background:
                        location.pathname === "/pharmacy/night-mode"
                          ? "#45079A"
                          : null,
                      borderRadius: "8px",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: open ? 1 : 0,
                        ml: open ? 0 : 3,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <NightModeIcon
                          sx={{
                            color: "#FFFFFF",
                          }}
                        />
                      </ListItemIcon>
                      {open ? (
                        <ListItemText
                          primary="NIGHT MODE"
                          sx={{
                            opacity: open ? 1 : 0,
                            // display: open ? "inline" : "none"
                          }}
                        />
                      ) : null}
                    </ListItemButton>
                  </Paper>
                }
                labelPlacement="start"
                sx={{
                  // display: "block",
                  my: 1.5,
                  width: open ? "200px" : "48px",
                  height: open ? "48px" : "48px",
                  ml: open ? 0 : 3.25,
                  // pr: 2,
                  // ml: open ? 1 : 3,
                  // mb: 0.5,
                }}
              />
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            width: "-webkit-fill-available",
            flexGrow: 1,
            p: 3,
          }}
        >
          {/* <DrawerHeader /> */}
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
