import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const Search = () => {

  const navigate = useNavigate();
  return (
    // <Typography variant='h5'>
    //   Lorem ipsum dolor sit amet.
    // </Typography>

    <>
      <Typography
        variant="h3"
        sx={{
          fontSize: "24px",
          width: "100%",
          color: "#45079A",
          lineHeight: "30px",
          alignSelf: "flex-start",
        }}
      >
        Search Medicine
      </Typography>
      <Card
        variant="outlined"
        sx={{
          mt: "43px",
          width: "478px",
          height: "204px",
          px: "21px",
          py: "23px",
          background: "#E8EAF6",
          border: "1px solid #E2E4E8",
          boxShadow: "0px 0px 4px rgba(51, 51, 51, 0.05)",
          bordeRadius: "8px",
        }}
      >
        {" "}
        <Typography
          sx={{
            width: "148px",
            height: "19px",
            fontSize: "12px",
            lineHeight: "160%",
            color: "#232323",
            mb: "6px",
          }}
        >
          Search by:
        </Typography>{" "}
        <TextField
          sx={{
            width: "436px",
            height: "41px",
            color: "#232323",
            background: "#FFFFFF",
            mb: "37px",
            border: "1px solid #E2E4E8",
            borderRadius: "0px 6px 6px 0px",
          }}
          id="outlined-basic"
          label="Enter medicine name:"
          variant="outlined"
          size="small"
        />
        <Button
          onClick={() => navigate("/pharmacy/search/results")}
          variant="contained"
          sx={{
            textTransform: "none",
            mx: "auto",
            px: "auto",
            width: "436px",
            height: "44px",
            background: "#455162",
            borderRadius: "4px",
          }}
        >
          Search
        </Button>
      </Card>
    </>
  );
};
