import React, { useState } from "react";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import "../../../Assets/Styles/css/Auth/index.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import pharm from "../../../Assets/Images/pharm.png";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { RedditTextField } from "../../../Components/Form";


const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#ffffff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#454081",
    },
  },
  typography: {
    fontFamily: ["Mulish", "Bold"].join(","),
  },
});

export const SetPassowrd = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="Org-Button">
          <Button variant="outlined" color="secondary">
            Login as an Organisation
          </Button>
        </div>
        <CssBaseline />
        <Container
          maxWidth="xl"
          disableGutters={true}
          sx={{
            marginTop: "5em",
            height: "60vh",
            bgcolor: "#4D488C",
            justifyContent: "space-between",
            flex: "flex-container",
            borderRadius: "40px",
          }}
        >
          <Grid
            container
            // rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid
              item
              xs={0}
              sm={4}
              md={6}
              sx={{
                backgroundImage: `url(${pharm})`,
                backgroundRepeat: "no-repeat",
                height: "60vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "40px 0px 0px 40px",
                opacity: "40%",
              }}
            />
            <Grid item xs={12} sm={8} md={6} sx={{ my: "auto" }}>
              <Box
                sx={{
                  // my: 8,
                  px: 2.5,
                //   pt: 1,
                  mx: 2,
                  display: "flex",
                  flexDirection: "column",
                gap: 1.5
                  // alignItems: "center",
                  // paddingTop: "20px",
                  // paddingBottom: "20px",
                }}
              >
                <Typography
                  component="h1"
                  variant="h4"
                  color="white"
                  justifyContent="start"
                >
                  Set your password
                </Typography>
                <Box component="form" noValidate sx={{}}>
                  <RedditTextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="email"
                    label="Password"
                    name="email"
                    autoComplete="email"
                    // autoFocus
                    variant="filled"
                    sx={{
                      input: {
                        color: "white",
                      },
                      label: {
                        color: "white",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff color="primary" />
                            ) : (
                              <Visibility color="primary" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <RedditTextField
                    label="Confirm Password"
                    required
                    id="#"
                    variant="filled"
                    fullWidth
                    size="small"
                    sx={{
                        type: "password",
                      input: {
                        color: "white",
                      },
                      label: {
                        color: "white",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff color="primary" />
                            ) : (
                              <Visibility color="primary" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{
                      textTransform: "none",
                      mt: 3,
                      mb: 2,
                      "&:hover": {
                        borderColor: "#f9f9f9",
                      },
                    }}
                  >
                    Set Password
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
}
