import React from 'react'

import PharmacyDrawer from './Drawer'
import { Outlet } from "react-router-dom";


const Layout = () => {
  return (
    <>
      <PharmacyDrawer>
        <Outlet />
      </PharmacyDrawer>
    </>
  );
}

export default Layout