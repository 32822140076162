import React from 'react'
import { Routes, Route,} from "react-router-dom";
import {Login, ForgetPassword, SetPassowrd, PasswordSeccess, BaseAuthRoute} from '../Pages/Auth';
import {Search, Results} from '../Pages/Pharm/Search'
import { Overview } from "../Pages/Pharm/Overview";
import Layout from '../Layouts/index'
import NotFound from '../Pages/NotFound';
import { Groups, List, Inventory } from '../Pages/Pharm/Inventory';
import { Cashier } from '../Pages/Pharm/Cashier';
import { PaymentHistory, Sales, Report } from '../Pages/Pharm/Report';
import { Settings, Support } from '../Pages/Pharm/Settings';
import Loader from '../Components/Loader'





const MainRoute = () => {
  return (
    <Routes>
      <Route path="/loading" element={<Loader />} />
      <Route path="/auth/" element={<BaseAuthRoute />}>
        <Route path="login" element={<Login />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="set-password" element={<SetPassowrd />} />
        <Route path="password-success" element={<PasswordSeccess />} />
      </Route>
      <Route path="/pharmacy/" element={<Layout />}>
        <Route path="search" element={<Search />} />
        <Route path="search/results" element={<Results />} />

        <Route path="inventory/" element={<Inventory />} />
        <Route path="inventory/groups" element={<Groups />} />
        <Route path="inventory/list" element={<List />} />

        <Route path="cashier" element={<Cashier />} />

        <Route path="report" element={<Report />} />
        <Route path="report/sales" element={<Sales />} />
        <Route path="report/payment-history" element={<PaymentHistory />} />

        <Route path="settings" element={<Settings />} />

        <Route path="support" element={<Support />} />

        <Route path="overview" element={<Overview />} />
      </Route>
      <Route path="/" element={""} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MainRoute