import React from "react";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import "../../../Assets/Styles/css/Auth/index.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import pharm from "../../../Assets/Images/pharm.png";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RememberMeIcon from "@mui/icons-material/RememberMe";
// import Link from '@mui/material/Link';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#ffffff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#454081",
    },
  },
  typography: {
    fontFamily: "Mulish",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
});

export const PasswordSeccess = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="Org-Button">
          <Button variant="outlined" color="secondary">
            Login as an Organisation
          </Button>
        </div>
        <CssBaseline />
        <Container
          maxWidth="xl"
          disableGutters={true}
          sx={{
            marginTop: "5em",
            height: "60vh",
            bgcolor: "#4D488C",
            justifyContent: "space-between",
            flex: "flex-container",
            borderRadius: "40px",
          }}
        >
          <Grid
            container
            // rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid
              item
              xs={0}
              sm={4}
              md={6}
              sx={{
                backgroundImage: `url(${pharm})`,
                backgroundRepeat: "no-repeat",
                height: "60vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "40px 0px 0px 40px",
                opacity: "40%",
              }}
            />
            <Grid item xs={12} sm={8} md={6} sx={{ my: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 3,
                  mx: 5,
                }}
              >
                <RememberMeIcon sx={{ fontSize: 70 }} color="primary" />
                <Typography
                  component="p"
                  variant="body1"
                  color="white"
                  justifyContent="start"
                  sx={{ my: 3 }}
                >
                  Password set
                  <br />
                  successfully
                </Typography>

                <Button
                  href="/auth/login"
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{
                    textTransform: "none",

                    "&:hover": {
                      borderColor: "#f9f9f9",
                    },
                  }}
                >
                    Login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
};
