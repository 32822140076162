// import React from "react";
// import "../Assets/Styles/css/Loader.css";

// const Loader = () => {
//   return (
//     <>
//       {/* <p>Please wait, while we</p>
//       <br />
//       <p>provide medicine details</p> */}
//       <div className="col-3">
//         <div className="snippet" data-title="dot-flashing">
//           <div className="stage">
//             <div className="dot-flashing"></div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Loader;

import React from "react";
import "../Assets/Styles/css/Loader.css";

const Loader = () => {
  return (
    <div className="col-sm-6 text-center"><div className="loader3">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div></div>
  );
};

export default Loader;
