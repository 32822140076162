import React from "react";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import "../../../Assets/Styles/css/Auth/index.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import pharm from "../../../Assets/Images/pharm.png";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#ffffff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#454081",
    },
  },
  typography: {
    fontFamily: "Mulish",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
});

export const ForgetPassword = () => {
  
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="Org-Button">
          <Button variant="outlined" color="secondary">
            Login as an Organisation
          </Button>
        </div>
        <CssBaseline />
        <Container
          maxWidth="xl"
          disableGutters={true}
          sx={{
            marginTop: "5em",
            height: "60vh",
            bgcolor: "#4D488C",
            justifyContent: "space-between",
            flex: "flex-container",
            borderRadius: "40px",
          }}
        >
          <Grid
            container
            // rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid
              item
              xs={0}
              sm={4}
              md={6}
              sx={{
                backgroundImage: `url(${pharm})`,
                backgroundRepeat: "no-repeat",
                height: "60vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "40px 0px 0px 40px",
                opacity: "40%",
              }}
            />
            <Grid item xs={12} sm={8} md={6} sx={{ my: "auto" }}>
              <Box
                sx={{
                  // my: 8,
                  px: 2.5,
                //   pt: 2,
                  mx: 2,
                  display: "flex",
                  flexDirection: "column",

                  // alignItems: "center",
                  // paddingTop: "20px",
                  // paddingBottom: "20px",
                }}
              >
                <Typography
                  component="h1"
                  variant="h4"
                  color="white"
                  justifyContent="start"
                >
                  Verification Required
                </Typography>
                <Box sx={{}}>
                  <Typography
                    component="p"
                    variant="body2"
                    color="white"
                    justifyContent="start"
                    sx={{my: 3}}
                  >
                    ol***@**********il.com
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    color="white"
                    justifyContent="start"
                  >
                    An email with a Password reset link has been sent to the
                    email shown above. Please check your mail.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
}
