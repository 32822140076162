import Typography from '@mui/material/Typography'
import React from 'react'

export const PaymentHistory = () => {
  return (
   <Typography variant='h1'>
Payment History

   </Typography>
  )
}

