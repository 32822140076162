
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { alpha, styled } from "@mui/material/styles";

export const RedditTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #5A53B1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#5A53B1",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "#5A53B1",
    },
    "&.Mui-focused": {
      backgroundColor: "#5A53B1",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));