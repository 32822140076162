import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.60039 2.79985C6.32735 2.79985 5.10645 3.30556 4.20628 4.20573C3.3061 5.10591 2.80039 6.32681 2.80039 7.59985C2.80039 8.87288 3.3061 10.0938 4.20628 10.994C5.10645 11.8941 6.32735 12.3998 7.60039 12.3998C8.87343 12.3998 10.0943 11.8941 10.9945 10.994C11.8947 10.0938 12.4004 8.87288 12.4004 7.59985C12.4004 6.32681 11.8947 5.10591 10.9945 4.20573C10.0943 3.30556 8.87343 2.79985 7.60039 2.79985ZM0.400391 7.59985C0.400246 6.46669 0.667562 5.3495 1.1806 4.33914C1.69363 3.32877 2.4379 2.45376 3.35287 1.78528C4.26783 1.11679 5.32767 0.6737 6.44617 0.492045C7.56467 0.31039 8.71025 0.395299 9.78975 0.739866C10.8692 1.08443 11.8522 1.67893 12.6586 2.475C13.465 3.27107 14.0721 4.24625 14.4306 5.3212C14.7891 6.39616 14.8888 7.54056 14.7216 8.66131C14.5544 9.78206 14.125 10.8475 13.4684 11.771L19.2488 17.5514C19.4674 17.7778 19.5883 18.0809 19.5856 18.3955C19.5829 18.7102 19.4567 19.0111 19.2342 19.2336C19.0117 19.4561 18.7107 19.5823 18.3961 19.5851C18.0814 19.5878 17.7783 19.4668 17.552 19.2482L11.7728 13.469C10.6956 14.235 9.42841 14.6896 8.11 14.7832C6.79159 14.8767 5.47288 14.6056 4.29835 13.9994C3.12382 13.3932 2.13881 12.4755 1.45126 11.3467C0.763703 10.2179 0.400134 8.92157 0.400391 7.59985Z"
        // fill="#45079A"
      />
    </SvgIcon>
  );
}

export const InventoryIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M8 10H11M8 14H11M5 10H5.01M5 14H5.01"
        stroke="#45079A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export const InventoryLightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M8 10H11M8 14H11M5 10H5.01M5 14H5.01"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export const CashierIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M23.2969 4.21875C23.6852 4.21875 24 3.90394 24 3.51562V0.703125C24 0.314812 23.6852 0 23.2969 0H14.8594C14.4711 0 14.1562 0.314812 14.1562 0.703125V3.51562C14.1562 3.90394 14.4711 4.21875 14.8594 4.21875H16.9688V5.625H11.25V2.10938C11.25 1.72106 10.9352 1.40625 10.5469 1.40625H4.92188C4.53356 1.40625 4.21875 1.72106 4.21875 2.10938V5.625H2.10938C0.946266 5.625 0 6.57127 0 7.73438C0 8.50683 0 21.1215 0 21.8906C0 23.0537 0.946266 24 2.10938 24H21.8906C23.0537 24 24 23.0537 24 21.8906C24 21.1213 24 8.50664 24 7.73438C24 6.57127 23.0537 5.625 21.8906 5.625H21.1875V4.21875H23.2969ZM5.625 2.8125H7.03125V3.51562C7.03125 3.90394 7.34606 4.21875 7.73438 4.21875C8.12269 4.21875 8.4375 3.90394 8.4375 3.51562V2.8125H9.84375V8.4375H5.625V2.8125ZM22.5938 21.8906C22.5938 22.2783 22.2783 22.5938 21.8906 22.5938H2.10938C1.72167 22.5938 1.40625 22.2783 1.40625 21.8906V18.375H22.5938V21.8906ZM21.8906 7.03125C22.2783 7.03125 22.5938 7.34667 22.5938 7.73438V16.9688H1.40625V7.73438C1.40625 7.34667 1.72167 7.03125 2.10938 7.03125H4.21875V8.4375H3.51562C3.12731 8.4375 2.8125 8.75231 2.8125 9.14062C2.8125 9.52894 3.12731 9.84375 3.51562 9.84375C3.85064 9.84375 11.618 9.84375 11.9531 9.84375C12.3414 9.84375 12.6562 9.52894 12.6562 9.14062C12.6562 8.75231 12.3414 8.4375 11.9531 8.4375H11.25V7.03125C11.6862 7.03125 21.3992 7.03125 21.8906 7.03125ZM18.375 5.625V4.21875H19.7812V5.625H18.375ZM15.5625 2.8125V1.40625H22.5938V2.8125C21.8928 2.8125 16.2517 2.8125 15.5625 2.8125Z"
        // fill="#45079A"
      />
    </SvgIcon>
  );
};

export const LogoutIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M11 13L15 9M15 9L11 5M15 9H1M6 13V14C6 14.7956 6.31607 15.5587 6.87868 16.1213C7.44129 16.6839 8.20435 17 9 17H16C16.7956 17 17.5587 16.6839 18.1213 16.1213C18.6839 15.5587 19 14.7956 19 14V4C19 3.20435 18.6839 2.44129 18.1213 1.87868C17.5587 1.31607 16.7956 1 16 1H9C8.20435 1 7.44129 1.31607 6.87868 1.87868C6.31607 2.44129 6 3.20435 6 4V5"
        stroke="#45079A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export const NightModeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M13.1948 13.8217C14.9232 14.1748 16.7173 14.0122 18.3541 13.354C17.6841 15.0213 16.5304 16.45 15.0417 17.4562C13.5529 18.4624 11.797 19.0001 10.0001 19C7.9105 18.9977 5.88674 18.2687 4.27577 16.9378C2.6648 15.6069 1.56695 13.757 1.17043 11.7054C0.773906 9.65374 1.1034 7.52805 2.10244 5.69272C3.10148 3.85739 4.70782 2.42673 6.64611 1.646C5.98791 3.28277 5.82527 5.0769 6.17839 6.80535C6.53151 8.53381 7.38484 10.1204 8.63229 11.3678C9.87974 12.6153 11.4663 13.4686 13.1948 13.8217Z"
        stroke="#45079A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export const OverviewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M1.055 9H3C3.53043 9 4.03914 9.21071 4.41421 9.58579C4.78929 9.96086 5 10.4696 5 11V12C5 12.5304 5.21071 13.0391 5.58579 13.4142C5.96086 13.7893 6.46957 14 7 14C7.53043 14 8.03914 14.2107 8.41421 14.5858C8.78929 14.9609 9 15.4696 9 16V18.945M6 1.935V3.5C6 4.16304 6.26339 4.79893 6.73223 5.26777C7.20107 5.73661 7.83696 6 8.5 6H9C9.53043 6 10.0391 6.21071 10.4142 6.58579C10.7893 6.96086 11 7.46957 11 8C11 8.53043 11.2107 9.03914 11.5858 9.41421C11.9609 9.78929 12.4696 10 13 10C13.5304 10 14.0391 9.78929 14.4142 9.41421C14.7893 9.03914 15 8.53043 15 8C15 7.46957 15.2107 6.96086 15.5858 6.58579C15.9609 6.21071 16.4696 6 17 6H18.064M13 18.488V16C13 15.4696 13.2107 14.9609 13.5858 14.5858C13.9609 14.2107 14.4696 14 15 14H18.064M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
        stroke="#45079A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export const ReportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M4.39278 2.95941C5.7187 1.9035 7.31582 1.24343 9.00034 1.05518V11.0002H18.9453C18.7571 12.6847 18.097 14.2818 17.0411 15.6077C15.9852 16.9337 14.5763 17.9345 12.9767 18.495C11.3771 19.0556 9.65169 19.1531 7.9991 18.7763C6.34651 18.3995 4.8339 17.5637 3.63536 16.3652C2.43681 15.1666 1.60104 13.654 1.22425 12.0014C0.847446 10.3488 0.944931 8.62343 1.50548 7.0238C2.06603 5.42417 3.06685 4.01533 4.39278 2.95941Z"
        stroke="#45079A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};


export const ReportLightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M4.39278 2.95941C5.7187 1.9035 7.31582 1.24343 9.00034 1.05518V11.0002H18.9453C18.7571 12.6847 18.097 14.2818 17.0411 15.6077C15.9852 16.9337 14.5763 17.9345 12.9767 18.495C11.3771 19.0556 9.65169 19.1531 7.9991 18.7763C6.34651 18.3995 4.8339 17.5637 3.63536 16.3652C2.43681 15.1666 1.60104 13.654 1.22425 12.0014C0.847446 10.3488 0.944931 8.62343 1.50548 7.0238C2.06603 5.42417 3.06685 4.01533 4.39278 2.95941Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export const SupportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M6.228 7C6.777 5.835 8.258 5 10 5C12.21 5 14 6.343 14 8C14 9.4 12.722 10.575 10.994 10.907C10.452 11.011 10 11.447 10 12M10 15H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
        stroke="#45079A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};



export const SettingsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M11.675 2.317C11.249 0.561 8.751 0.561 8.325 2.317C8.049 3.452 6.749 3.99 5.753 3.382C4.209 2.442 2.443 4.209 3.383 5.752C3.5243 5.98375 3.60889 6.24559 3.62987 6.51621C3.65085 6.78683 3.60764 7.05859 3.50375 7.30935C3.39985 7.56011 3.23822 7.7828 3.032 7.95929C2.82578 8.13578 2.5808 8.26108 2.317 8.325C0.561 8.751 0.561 11.249 2.317 11.675C2.58056 11.7391 2.82529 11.8645 3.03127 12.0409C3.23726 12.2174 3.3987 12.44 3.50247 12.6906C3.60624 12.9412 3.64942 13.2128 3.62848 13.4832C3.60755 13.7537 3.5231 14.0153 3.382 14.247C2.442 15.791 4.209 17.557 5.752 16.617C5.98375 16.4757 6.24559 16.3911 6.51621 16.3701C6.78683 16.3491 7.05859 16.3924 7.30935 16.4963C7.56011 16.6001 7.7828 16.7618 7.95929 16.968C8.13578 17.1742 8.26108 17.4192 8.325 17.683C8.751 19.439 11.249 19.439 11.675 17.683C11.7391 17.4194 11.8645 17.1747 12.0409 16.9687C12.2174 16.7627 12.44 16.6013 12.6906 16.4975C12.9412 16.3938 13.2128 16.3506 13.4832 16.3715C13.7537 16.3924 14.0153 16.4769 14.247 16.618C15.791 17.558 17.557 15.791 16.617 14.248C16.4757 14.0162 16.3911 13.7544 16.3701 13.4838C16.3491 13.2132 16.3924 12.9414 16.4963 12.6907C16.6001 12.4399 16.7618 12.2172 16.968 12.0407C17.1742 11.8642 17.4192 11.7389 17.683 11.675C19.439 11.249 19.439 8.751 17.683 8.325C17.4194 8.26091 17.1747 8.13553 16.9687 7.95905C16.7627 7.78258 16.6013 7.55999 16.4975 7.30938C16.3938 7.05877 16.3506 6.78721 16.3715 6.51677C16.3924 6.24634 16.4769 5.98466 16.618 5.753C17.558 4.209 15.791 2.443 14.248 3.383C14.0162 3.5243 13.7544 3.60889 13.4838 3.62987C13.2132 3.65085 12.9414 3.60764 12.6907 3.50375C12.4399 3.39985 12.2172 3.23822 12.0407 3.032C11.8642 2.82578 11.7389 2.5808 11.675 2.317Z"
        stroke="#45079A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      ;
    </SvgIcon>
  );
};

export const FirstDropDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M5 12L7 14L11 10"
          stroke="#45079A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      ;
    </SvgIcon>
  );
};

export const SecondDropDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...props}
    >
      <path
        d="M16.364 3.636L12.828 7.172M16.364 3.636C14.6762 1.94817 12.3869 1 10 1C7.61305 1 5.32383 1.94817 3.636 3.636M16.364 3.636C18.0518 5.32383 19 7.61305 19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5283 16.364 16.364M12.828 7.172C12.0779 6.42185 11.0609 6 10 6C8.93913 6 7.92215 6.42185 7.172 7.172M12.828 7.172C13.5781 7.92215 14 8.93913 14 10C14 11.0609 13.5781 12.0779 12.828 12.828M12.828 12.828L16.364 16.364M12.828 12.828C12.0779 13.5781 11.0609 14 10 14C8.93913 14 7.92215 13.5781 7.172 12.828M16.364 16.364C15.5283 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47173 17.1997 3.636 16.364M7.172 7.172L3.636 3.636M7.172 7.172C6.42185 7.92215 6 8.93913 6 10C6 11.0609 6.42185 12.0779 7.172 12.828M3.636 3.636C1.94817 5.32383 1 7.61305 1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80027 15.5283 3.636 16.364M7.172 12.828L3.636 16.364"
        stroke="#45079A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      ;
    </SvgIcon>
  );
};

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#45079A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));